import { createToast } from "mosha-vue-toastify";
import { useAbility } from "@casl/vue";
import { mapGetters } from "vuex";

const ListViewMixin = {
  data: () => ({
    // default page
    progressFilter: false,
    filter: {
      phone: null,
      selectCompany: [],
      selectStatus: [],
      selectModelType: [],
      selectService: [],
      selectServiceReport: null,
      selectCompanyReport: [],
      selectBrandname: [],
      selectCompanyQuantity: null,
      selectSmstype: [],
      selectSector: [],
      selectOa: [],
      selectOperator: [],
      selectSupplier: null,
      selectZnsTemplate: [],
      selectProduct: [],
      selectTypeWallet: null,
      sendDate: "",
      dateReport: {
        startDate: "",
        endDate: "",
      },
      selectServiceType: null,
    },
    defaultFilter: {
      phone: null,
      selectCompany: [],
      selectStatus: [],
      selectModelType: [],
      selectService: [],
      selectServiceReport: null,
      selectCompanyReport: [],
      selectBrandname: [],
      selectCompanyQuantity: null,
      selectSmstype: [],
      selectSector: [],
      selectOa: [],
      selectOperator: [],
      selectSupplier: null,
      selectZnsTemplate: [],
      selectProduct: [],
      selectTypeWallet: null,
      sendDate: "",
      dateReport: {
        startDate: "",
        endDate: "",
      },
      selectServiceType: null,
    },
    locale: process.env.VUE_APP_I18N_LOCALE,
    page: 1,
    searchText: "",
    heigthTable: 600,
    allowedTypes: ["application/pdf", "image/png", "image/jpeg", "image/jpg"],
    acceptFile: "application/pdf, image/png, image/jpeg, image/jpg",
    // allowed page sizes
    pageSizes: [
      { name: "10", value: 10 },
      { name: "20", value: 20 },
      { name: "50", value: 50 },
    ],
    paging: {
      page: 1,
      itemsPerPage: 20,
    },
    pageSizesReport: [
      { name: "20", value: 20 },
      { name: "50", value: 50 },
      { name: "100", value: 100 },
    ],
    pagingReport: {
      page: 1,
      itemsPerPage: 50,
    },
  }),

  created() {
    // kiểm tra xem component này có cần tự động load data mỗi khi được tạo không?
    let autoLoad = typeof this.autoLoad === "undefined" ? true : this.autoLoad;
    if (autoLoad) {
      // lấy dữ liệu lần đầu khi component được tạo
      this.search();
    }
  },

  computed: {
    ...mapGetters({
      callBackParams: "report/callBackParams",
      callBackParamsTool: "tool/callBackParams",
    }),
  },

  methods: {
    async search() {
      if (this.loadData) {
        console.log("load data with filter", this.filter);
        let extraSearch = this.extraSearch || {};
        this.progressFilter = true;
        const resp = await this.loadData({
          serviceIds: this.filter.selectService,
          status: this.filter.selectStatus,
          messageTypeIds: this.filter.selectSmstype,
          sectorIds: this.filter.selectSector,
          createdAt: this.filter.sendDate,
          templateIds: this.filter.selectZnsTemplate,
          modelType: this.filter.selectModelType,
          serviceId: this.filter.selectServiceReport,
          companyIds:
            this.filter.selectCompanyReport.length > 0
              ? this.filter.selectCompanyReport
              : this.filter.selectCompany,
          brandNameIds: this.filter.selectBrandname,
          oaIds: this.filter.selectOa,
          homeNetworkIds: this.filter.selectOperator,
          startDate: this.filter.dateReport.startDate,
          endDate: this.filter.dateReport.endDate,
          pageNumber: this.paging.page - 1,
          pageSize: this.paging.itemsPerPage,
          serviceType: this.filter.selectServiceType,
          search: this.searchText, //encodeURIComponent(this.searchText)
          ...extraSearch,
        });
        if (resp.status && resp.status == 200) {
          this.progressFilter = false;
        }
      } else {
        console.log("component has no method `loadData`");
      }
    },
    async searchPageReport() {
      console.log("callback", this.callBackParams);
      if (this.loadData) {
        let extraSearch = this.extraSearch || {};
        this.progressFilter = true;
        const resp = await this.loadData({
          dataPage: this.callBackParams,
          pageNumber: this.pagingReport.page - 1,
          pageSize: this.pagingReport.itemsPerPage,
          search: this.searchText, //encodeURIComponent(this.searchText)
          typePage: "PAGE",
          typeReport: this.callBackParams.typeReport,
          ...extraSearch,
        });
        if (resp.status && resp.status == 200) {
          this.progressFilter = false;
        }
      } else {
        console.log("component has no method `loadData`");
      }
    },
    async searchSelectPageReport() {
      console.log("callback", this.callBackParams);
      if (this.loadData) {
        let extraSearch = this.extraSearch || {};
        this.pagingReport.page = 1;
        this.progressFilter = true;
        const resp = await this.loadData({
          dataPage: this.callBackParams,
          pageNumber: this.pagingReport.page - 1,
          pageSize: this.pagingReport.itemsPerPage,
          search: this.searchText, //encodeURIComponent(this.searchText)
          typePage: "PAGE",
          typeReport: this.callBackParams.typeReport,
          ...extraSearch,
        });
        if (resp.status && resp.status == 200) {
          this.progressFilter = false;
        }
      } else {
        console.log("component has no method `loadData`");
      }
    },
    async searchPageTool() {
      console.log("callback", this.callBackParamsTool);
      if (this.loadData) {
        let extraSearch = this.extraSearch || {};
        this.progressFilter = true;
        const resp = await this.loadData({
          dataPage: this.callBackParamsTool,
          pageNumber: this.pagingReport.page - 1,
          pageSize: this.pagingReport.itemsPerPage,
          search: this.searchText, //encodeURIComponent(this.searchText)
          typePage: "PAGE",
          typeTool: this.callBackParamsTool.typeTool,
          ...extraSearch,
        });
        if (resp.status && resp.status == 200) {
          this.progressFilter = false;
        }
      } else {
        console.log("component has no method `loadData`");
      }
    },
    async searchSelectPageTool() {
      console.log("callback", this.callBackParamsTool);
      if (this.loadData) {
        let extraSearch = this.extraSearch || {};
        this.pagingReport.page = 1;
        this.progressFilter = true;
        const resp = await this.loadData({
          dataPage: this.callBackParamsTool,
          pageNumber: this.pagingReport.page - 1,
          pageSize: this.pagingReport.itemsPerPage,
          search: this.searchText, //encodeURIComponent(this.searchText)
          typePage: "PAGE",
          typeTool: this.callBackParamsTool.typeTool,
          ...extraSearch,
        });
        if (resp.status && resp.status == 200) {
          this.progressFilter = false;
        }
      } else {
        console.log("component has no method `loadData`");
      }
    },
    reload() {
      if (this.loadData) {
        let extraSearch = this.extraSearch || {};
        this.loadData({
          companyIds: this.filter.selectCompany,
          serviceIds: this.filter.selectService,
          status: this.filter.selectStatus,
          createdAt: this.filter.sendDate,
          modelType: this.filter.selectModelType,
          pageNumber: this.paging.page - 1,
          pageSize: this.paging.itemsPerPage,
          search: this.searchText,
          ...extraSearch,
        }).then((response) => {
          console.log("reload data: ", response);
          if (response.length == 0) {
            this.paging.page = 1;
            this.loadData({
              companyIds: this.filter.selectCompany,
              serviceIds: this.filter.selectService,
              status: this.filter.selectStatus,
              createdAt: this.filter.sendDate,
              modelType: this.filter.selectModelType,
              pageNumber: this.paging.page - 1,
              pageSize: this.paging.itemsPerPage,
              search: this.searchText,
              ...extraSearch,
            });
          }
        });
      } else {
        console.log("component has no method `loadData`");
      }
    },
    searchPage() {
      console.log("pageable: ", this.paging);
      console.log("hahaha", this.filter);
      if (this.loadData) {
        let extraSearch = this.extraSearch || {};
        this.paging.page = 1;
        this.loadData({
          companyIds: this.filter.selectCompany,
          serviceIds: this.filter.selectService,
          status: this.filter.selectStatus,
          createdAt: this.filter.sendDate,
          modelType: this.filter.selectModelType,
          serviceId: this.filter.selectServiceReport,
          companyId: this.filter.selectCompanyReport,
          brandNameIds: this.filter.selectBrandname,
          oaIds: this.filter.selectOa,
          startDate: this.filter.dateReport.startDate,
          endDate: this.filter.dateReport.endDate,
          pageNumber: this.paging.page - 1,
          pageSize: this.paging.itemsPerPage,
          search: this.searchText,
          ...extraSearch,
        });
      } else {
        console.log("component has no method `loadData`");
      }
    },
    checkGroupDisable(actions, permission) {
      //permissions = ["read|notification"] ; read => action,  notification => permission
      let disable = false;
      for (let action of actions) {
        if (this.isPermission(action, permission)) {
          disable = true;
          break;
        }
      }
      return disable;
    },
    checkDisable(status, statusEnable, permission, permissionType) {
      let disable = true;
      if (this.isPermission(permission, permissionType)) {
        if (statusEnable?.includes(status)) {
          disable = false;
        }
      }
      return disable;
    },
    isPermission(permission, type) {
      const ability = useAbility();
      return ability.can(
        this.mapPermission(permission),
        this.mapPermissionType(type)
      );
    },
    mapStatus(type) {
      const mapType = {
        draft: "DRAFT", // bản nháp
        pending: "PENDING", // chờ duyệt
        active: "ACTIVE", // đang hoạt động
        inactive: "INACTIVE", // không hoạt động
        lock: "LOCK", // khóa
        unlock: "UNLOCK", // mở khóa
        expiry: "EXPIRY", // hết hạn
        waittingsend: "WAITING_SEND", //chờ gửi
        sending: "SENDING", //đang gửi
        completed: "COMPLETED", // thành công
        cancel: "CANCEL", //hủy
        waitingforapproval: "WAITING_FOR_APPROVAL", //chờ phê duyệt
        approved: "APPROVED", //phê duyệt
      };
      return mapType[type] || type;
    },
    mapPermissionType(type) {
      const mapType = {
        user: "USER",
        brandname: "BRAND_NAME",
        company: "COMPANY",
        phone: "PHONE",
        campaign: "CAMPAIGN",
        operator: "HOME_NETWORK",
        smstemplate: "MESSAGE_FORM",
        smstype: "MESSAGE_TYPE",
        order: "ORDER",
        permission: "PERMISSION",
        field: "SECTOR",
        role: "ROLE",
        attribute: "ATTRIBUTE",
        service: "SERVICE",
        category: "CATEGORY",
        discount: "DISCOUNT",
        wallet: "WALLET",
        transaction: "TRANSACTION",
        product: "PRODUCT",
        zns: "ZNS",
        znstemplate: "TEMPLATE",
        report: "REPORT",
      };

      return mapType[type] || type;
    },
    mapPermission(type) {
      const mapType = {
        create: "CREATE",
        read: "READ",
        edit: "EDIT",
        delete: "DELETE",
        approve: "APPROVE",
        addpermission: "ADD_PERMISSION",
        resetpass: "RESET_PASS",
        lock: "LOCK",
        renew: "RENEW",
        createfor: "CREATE_FOR",
        assign: "ASSIGN",
        editAssign: "EDIT_ASSIGN",
        import: "IMPORT",
        export: "EXPORT",
        genAppPw: "GEN_APP_PASSWORD",
      };

      return mapType[type] || type;
    },

    // getColorResult(item) {
    //   switch (true) {
    //     case item.raw.errorCode === "000" && item.raw.status === "COMPLETED":
    //     case item.raw.status === "COMPLETED" &&
    //       item.raw.messageStatus === "ESME_ROK":
    //       return "success";
    //     default:
    //       return "error";
    //   }
    // },
    getColorResult(item) {
      switch (true) {
        // case item.raw.status === "COMPLETED" &&
        //   item.raw.messageStatus === "ESME_ROK" &&
        //   item.raw.errorCode === "000":
        // case item.raw.status === "COMPLETED" &&
        //   item.raw.messageStatus === null &&
        //   item.raw.errorCode === null:
        // case item.raw.status === "COMPLETED" &&
        //   item.raw.errorCode === "000" &&
        //   item.raw.messageStatus === null:
        // case item.raw.status === "COMPLETED" &&
        //   item.raw.errorCode === null &&
        //   item.raw.messageStatus === "ESME_ROK":
        case item.raw.status === "COMPLETED" && item.raw.messageStatus === null:
        case item.raw.status === "COMPLETED" &&
          item.raw.messageStatus === "ESME_ROK":
        case item.raw.status === "COMPLETED" &&
          item.raw.messageStatus === "DELIVRD":
          return "success";
        default:
          return "error";
      }
    },
    getMessageResult(item) {
      switch (true) {
        case item.raw.status === "COMPLETED" && item.raw.messageStatus === null:
        case item.raw.status === "COMPLETED" &&
          item.raw.messageStatus === "ESME_ROK":
        case item.raw.status === "COMPLETED" &&
          item.raw.messageStatus === "DELIVRD":
          return "Thành công";
        default:
          return "Thất bại";
      }
    },

    vChipColor(status) {
      let color = null;

      switch (status) {
        case "ACTIVE":
        case "APPROVED":
        case "COMPLETED":
        case "SUCCESS":
          color = "success";
          break;
        case "INACTIVE":
        case "LOCK":
        case "EXPIRY":
        case "REJECT":
        case "CANCEL":
        case "FAILED":
          color = "error";
          break;
        case "WAITING_SEND":
        case "PROCESSING":
          color = "info";
          break;
        default:
          color = "grey";
      }

      return color;
    },
    iconFileDownload(fileName) {
      let icon;
      if (fileName) {
        const fileExtension = fileName.split(".").pop().toLowerCase();
        switch (fileExtension) {
          case "jpeg":
          case "jpg":
            icon = "mdi-file-jpg-box";
            break;
          case "png":
            icon = "mdi-file-png-box";
            break;
          case "pdf":
            icon = "mdi-file-pdf-box";
            break;
          case "docx":
          case "doc":
            icon = "mdi-file-word-box";
            break;
          case "xls":
          case "xlsx":
            icon = "mdi-file-excel-box";
            break;
          default:
            icon = "mdi-file";
        }
        return icon;
      }
    },
    // fixHeightTableReport() {
    //   let newHeight = window.innerHeight;
    //   if (newHeight > 920) {
    //     newHeight = window.innerHeight * (1 / 2.85);
    //   } else if (newHeight > 840) {
    //     newHeight = window.innerHeight * (1 / 2.95);
    //   } else if (newHeight > 740) {
    //     newHeight = window.innerHeight * (1 / 3.15);
    //   } else if (newHeight > 610) {
    //     newHeight = window.innerHeight * (1 / 3.6);
    //   }
    //   return newHeight;
    // },
    fixHeightTable() {
      console.log("newHeight: ", window.innerHeight);
      let newHeight = window.innerHeight;

      if (newHeight > 920) {
        newHeight = window.innerHeight * (1 / 1.45);
      } else if (newHeight > 840) {
        newHeight = window.innerHeight * (1 / 1.5);
      } else if (newHeight > 740) {
        newHeight = window.innerHeight * (1 / 1.55);
      } else if (newHeight > 610) {
        newHeight = window.innerHeight * (1 / 1.6);
      } else if (newHeight > 560) {
        newHeight = window.innerHeight * (1 / 1.9);
      }

      return newHeight;
    },
    convertToLocalTime(utcDateString) {
      const date = new Date(utcDateString);
      const timeZoneOffset = date.getTimezoneOffset();
      date.setMinutes(date.getMinutes() - timeZoneOffset);
      return date.toLocaleString(this.locale);
    },
    convertToLocalDate(utcDateString) {
      const date = new Date(utcDateString);
      const timeZoneOffset = date.getTimezoneOffset();
      date.setMinutes(date.getMinutes() - timeZoneOffset);
      return date.toLocaleDateString(this.locale);
    },
    convertToLocalEditDate(utcDateString) {
      const date = new Date(utcDateString);
      date.setHours(date.getHours() + 7);
      return date.toLocaleDateString("en-CA");
    },
    toastUpdateSuccess() {
      createToast(this.$t("messager.notification.description.success"), {
        position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        type: "success", //'info', 'danger', 'warning', 'success', 'default'
        transition: "zoom", //'bounce', 'zoom', 'slide'
        showIcon: true,
        hideProgressBar: true,
        timeout: 3000,
      });
    },
    toastFilterSuccess(value) {
      createToast(value, {
        position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        type: "success", //'info', 'danger', 'warning', 'success', 'default'
        transition: "zoom", //'bounce', 'zoom', 'slide'
        showIcon: true,
        hideProgressBar: true,
        timeout: 3000,
      });
    },
    toastCreateSuccess() {
      createToast(this.$t("messager.notification.description.add-success"), {
        position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        type: "success", //'info', 'danger', 'warning', 'success', 'default'
        transition: "zoom", //'bounce', 'zoom', 'slide'
        showIcon: true,
        hideProgressBar: true,
        timeout: 3000,
      });
    },
    toastDeleteSuccess() {
      createToast(this.$t("messager.notification.description.del-success"), {
        position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        type: "success", //'info', 'danger', 'warning', 'success', 'default'
        transition: "zoom", //'bounce', 'zoom', 'slide'
        showIcon: true,
        hideProgressBar: true,
        timeout: 3000,
      });
    },
    toastApprovedSuccess() {
      createToast(
        this.$t("messager.notification.description.approvedSuccess"),
        {
          position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
          type: "success", //'info', 'danger', 'warning', 'success', 'default'
          transition: "zoom", //'bounce', 'zoom', 'slide'
          showIcon: true,
          hideProgressBar: true,
          timeout: 3000,
        }
      );
    },
    toastRejectApprovedSuccess() {
      createToast(
        this.$t("messager.notification.description.rejectApprovedSuccess"),
        {
          position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
          type: "success", //'info', 'danger', 'warning', 'success', 'default'
          transition: "zoom", //'bounce', 'zoom', 'slide'
          showIcon: true,
          hideProgressBar: true,
          timeout: 3000,
        }
      );
    },
    toastExtendSuccess() {
      createToast(this.$t("messager.notification.description.extendSuccess"), {
        position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        type: "success", //'info', 'danger', 'warning', 'success', 'default'
        transition: "zoom", //'bounce', 'zoom', 'slide'
        showIcon: true,
        hideProgressBar: true,
        timeout: 3000,
      });
    },
    toastSendApprovedSuccess() {
      createToast(
        this.$t("messager.notification.description.sendApprovedSuccess"),
        {
          position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
          type: "success", //'info', 'danger', 'warning', 'success', 'default'
          transition: "zoom", //'bounce', 'zoom', 'slide'
          showIcon: true,
          hideProgressBar: true,
          timeout: 3000,
        }
      );
    },
    toastCancelSuccess() {
      createToast(this.$t("messager.notification.description.cancelCampaign"), {
        position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        type: "success", //'info', 'danger', 'warning', 'success', 'default'
        transition: "zoom", //'bounce', 'zoom', 'slide'
        showIcon: true,
        hideProgressBar: true,
        timeout: 3000,
      });
    },
    toastResetPasswordSuccess() {
      createToast(this.$t("messager.notification.description.rspassSuccess"), {
        position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        type: "success", //'info', 'danger', 'warning', 'success', 'default'
        transition: "zoom", //'bounce', 'zoom', 'slide'
        showIcon: true,
        hideProgressBar: true,
        timeout: 3000,
      });
    },
    toastChangePasswordSuccess() {
      createToast(
        this.$t("messager.notification.description.changePassSuccess"),
        {
          position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
          type: "success", //'info', 'danger', 'warning', 'success', 'default'
          transition: "zoom", //'bounce', 'zoom', 'slide'
          showIcon: true,
          hideProgressBar: true,
          timeout: 3000,
        }
      );
    },
    toastDirectionSucces() {
      createToast(
        this.$t("messager.notification.description.directionSuccess"),
        {
          position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
          type: "success", //'info', 'danger', 'warning', 'success', 'default'
          transition: "zoom", //'bounce', 'zoom', 'slide'
          showIcon: true,
          hideProgressBar: true,
          timeout: 3000,
        }
      );
    },
    toastChangeZnsTemplate() {
      createToast(this.$t("messager.notification.description.changeZns"), {
        position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        type: "danger", //'info', 'danger', 'warning', 'success', 'default'
        transition: "zoom", //'bounce', 'zoom', 'slide'
        showIcon: true,
        hideProgressBar: true,
        timeout: 3000,
      });
    },
    toastSyncSucces() {
      createToast(this.$t("messager.notification.description.syncSuccess"), {
        position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        type: "success", //'info', 'danger', 'warning', 'success', 'default'
        transition: "zoom", //'bounce', 'zoom', 'slide'
        showIcon: true,
        hideProgressBar: true,
        timeout: 3000,
      });
    },
    toastSendAppPasswordSuccess() {
      createToast(
        this.$t("messager.notification.description.sendAppPasswordSuccess"),
        {
          position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
          type: "success", //'info', 'danger', 'warning', 'success', 'default'
          transition: "zoom", //'bounce', 'zoom', 'slide'
          showIcon: true,
          hideProgressBar: true,
          timeout: 3000,
        }
      );
    },
    toastCopySuccess() {
      createToast(this.$t("messager.notification.description.copy"), {
        position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        type: "success", //'info', 'danger', 'warning', 'success', 'default'
        transition: "zoom", //'bounce', 'zoom', 'slide'
        showIcon: true,
        hideProgressBar: true,
        timeout: 3000,
      });
    },
    toastWaitExportFile() {
      createToast(this.$t("messager.notification.description.waitExport"), {
        position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        type: "info", //'info', 'danger', 'warning', 'success', 'default'
        transition: "zoom", //'bounce', 'zoom', 'slide'
        showIcon: true,
        hideProgressBar: true,
        timeout: 4000,
      });
    },
    toastErrorExportFile() {
      createToast(this.$t("messager.notification.description.errorExport"), {
        position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
        type: "danger", //'info', 'danger', 'warning', 'success', 'default'
        transition: "bounce", //'bounce', 'zoom', 'slide'
        showIcon: true,
        hideProgressBar: true,
        timeout: 3000,
      });
    },
    toastError(description) {
      createToast(
        {
          title: this.$t("messager.notification.description.warning"),
          description: description,
        },
        {
          position: "top-right", //'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
          type: "warning", //'info', 'danger', 'warning', 'success', 'default'
          transition: "zoom", //'bounce', 'zoom', 'slide'
          showIcon: true,
          hideProgressBar: true,
          timeout: 3000,
        }
      );
    },
    isError(status_code) {
      let isError = false;

      switch (status_code) {
        case 400:
        case 401:
        case 403:
        case 404:
        case 500:
          isError = true;
          break;
      }

      return isError;
    },

    PopupCenter(url, title, w, h) {
      let userAgent = navigator.userAgent,
        mobile = function () {
          return (
            /\b(iPhone|iP[ao]d)/.test(userAgent) ||
            /\b(iP[ao]d)/.test(userAgent) ||
            /Android/i.test(userAgent) ||
            /Mobile/i.test(userAgent)
          );
        },
        screenX =
          typeof window.screenX != "undefined"
            ? window.screenX
            : window.screenLeft,
        screenY =
          typeof window.screenY != "undefined"
            ? window.screenY
            : window.screenTop,
        outerWidth =
          typeof window.outerWidth != "undefined"
            ? window.outerWidth
            : document.documentElement.clientWidth,
        outerHeight =
          typeof window.outerHeight != "undefined"
            ? window.outerHeight
            : document.documentElement.clientHeight - 22,
        targetWidth = mobile() ? null : w,
        targetHeight = mobile() ? null : h,
        V = screenX < 0 ? window.screen.width + screenX : screenX,
        left = parseInt(V + (outerWidth - targetWidth) / 2, 10),
        right = parseInt(screenY + (outerHeight - targetHeight) / 2.5, 10),
        features = [];
      if (targetWidth !== null) {
        features.push("width=" + targetWidth);
      }
      if (targetHeight !== null) {
        features.push("height=" + targetHeight);
      }
      features.push("left=" + left);
      features.push("top=" + right);
      features.push("scrollbars=1");

      let newWindow = window.open(url, title, features.join(","));

      if (window.focus) {
        newWindow.focus();
      }

      return newWindow;
    },
  },
};

export default ListViewMixin;
