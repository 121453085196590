import axios from "axios";
import ListViewMixin from "@/mixins/ListViewMixin";

const state = {
  templateZns: null,
  campaignZns: null,
  pageableTemplateZns: {},
  pageable: {},
  search: {
    pageSize: 20,
  },
};

const getters = {
  templateZns: (state) => state.templateZns,
  campaignZns: (state) => state.campaignZns,
  pageable: (state) => state.pageable,
  pageableTemplateZns: (state) => state.pageableTemplateZns,
  searchParams: (state) => state.search,
};

const actions = {
  async setZnsFallback({ commit }, params) {
    let response = await axios.post(`/zns/templates/${params.id}/fallback/${params.smsTemplateId}`)
    return response;
  },
  async getTemplateZns({ commit, getters }, params) {
    let searchParam = getters.searchParams;

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params?.companyIds?.length) {
      params["companyIds"] = params.companyIds.map((item) => item.id).join(",");
    }

    if (params.status && typeof params.status == "object") {
      params["status"] = params.status.map((item) => item).join(",");
    }

    if (params?.ids?.length) {
      params["ids"] = params.ids.map((item) => item.id).join(",");
    }

    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}

    const response = await axios.get(
      `/zns/templates?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );

    if (response.status == 200) {
      commit("setTemplateZns", response.data);
      return response;
    }
    return [];
  },

  async getCampaignZns({ commit, getters }, params) {
    console.log("campaign/getCampaigns", params);

    let searchParam = getters.searchParams;

    params["type"] = "zns";

    params["pageSize"] =
      params["pageSize"] === undefined
        ? searchParam.pageSize
        : params["pageSize"];
    params["pageNumber"] =
      params["pageNumber"] === undefined
        ? searchParam.pageNumber
        : params["pageNumber"];

    commit("setPaging", {
      pageSize: params["pageSize"],
      page: params["pageNumber"],
    });

    if (params?.companyIds?.length) {
      params["companyIds"] = params.companyIds.map((item) => item.id).join(",");
    }

    if (params.status && typeof params.status == "object") {
      params["status"] = params.status.map((item) => item).join(",");
    }
    // dùng để xử lý khi search chứa ký tự đặc biệt
    // đặc biệt ký tự [] không thể mã hóa trong params vì axios sẽ hiểu là array
    const search = params.search;
    //xóa key search trong params và mã hóa trên đường dẫn
    delete params.search; //?search=${search ? encodeURIComponent(search) : ''}
    const response = await axios.get(
      `/campaigns?search=${search ? encodeURIComponent(search) : ""}`,
      { params: params }
    );
    if (response.status == 200) {
      commit("setCampaignZns", response.data);
      return response;
    }
    return [];
  },

  async syncTemplateOa({ commit }, params) {
    try {
      let response = await axios.get(`/zns/templates/synch`, {
        params: params,
      });
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      } else {
        return error.response;
      }
    }
  },

  async createCampaignZalo({ commit }, params) {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      formData.append("name", params.name);
      formData.append("oaId", params.oa.oaId);
      formData.append("walletId", params.wallet.id);
      formData.append("templateId", params.znsTemplate.id);
      formData.append("sendDate", params.sendDate);
      formData.append("excelFile", params.file);
      formData.append("companyId", params.oa.company.id);
      const response = await axios.post(`/campaigns/zns`, formData, config);
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async updateCampaignZalo({ commit }, params) {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      formData.append("name", params.name);
      formData.append("oaId", params.oa.oaId);
      formData.append("walletId", params.wallet.id);
      formData.append("templateId", params.znsTemplate.id);
      formData.append("sendDate", params.sendDate);
      formData.append("companyId", params.oa.company.id);
      if (params.file) {
        formData.append("excelFile", params.file);
      }
      if (params.stepType) {
        formData.append("type", "BACK");
      }
      const response = await axios.put(
        `/campaigns/zns/${params.id}/update`,
        formData,
        config
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async confirmCampaignZalo({ commit }, params) {
    try {
      console.log("update campaign bending");
      let formData = new FormData();
      formData.append("sendDate", params.sendDate);
      let response = await axios.put(
        `/campaigns/zns/${params.id}/confirm`,
        formData
      );
      return response;
    } catch (error) {
      if (ListViewMixin.methods.isError(error.response.status)) {
        return { status: error.response.status, errors: error.response.data };
      }
    }
  },

  async exportFileSample({ commit }, params) {
    let nameFile =
      "SampleTemplate_" + params.templateId + "." + params.fileType;
    axios({
      url: `/campaigns/export/${params.idDownload}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      // create a new Blob object with the file content
      let blob = new Blob([response.data], {
        type: "application/octet-stream",
      });

      // create a URL for the Blob object
      let url = URL.createObjectURL(blob);

      // create a link and simulate a click on it to trigger the download
      let link = document.createElement("a");
      link.href = url;
      link.download = nameFile;
      link.click();

      // release the URL object
      URL.revokeObjectURL(url);
    });
  },

  async exportFilePhoneErrorZns({ commit, getters }, params) {
    console.log("campaign/exportFilePhoneError", params);
    axios({
      url: `/campaigns/zns/${params.id}/export-to-excel`,
      method: "GET",
      responseType: "blob",
      params: params,
    }).then((response) => {
      // create a new Blob object with the file content
      let blob = new Blob([response.data], {
        type: "application/octet-stream",
      });

      // create a URL for the Blob object
      let url = URL.createObjectURL(blob);

      // create a link and simulate a click on it to trigger the download
      let link = document.createElement("a");
      link.href = url;
      link.download = "So_thue_bao_loi.xlsx";
      link.click();

      // release the URL object
      URL.revokeObjectURL(url);
    });
  },
};

const mutations = {
  setTemplateZns(state, data) {
    state.templateZns = data.data;
    state.pageableTemplateZns = data.pageable;
  },
  setCampaignZns(state, data) {
    state.campaignZns = data.data;
    state.pageable = data.pageable;
  },
  setPaging(state, data) {
    state.search.pageSize = data.pageSize;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
